/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import styles from '../styles/privacyPolicy.module.scss';

const PrivacyPolicy = () => (
  <Layout>
    <SEO />
    <div className={styles.banner} />
    <div className={styles.container}>
      <div className={`${styles.pageContainer} container`}>
        <h1 className={styles.title}>
          Privacy Policy
        </h1>
        <div className={styles.content}>
          <p>
            This privacy policy sets out the information practices we follow including the type of information is collected, how the information is collected, how the information is used and with whom it is shared.
          </p>
          <br />

          {/* Definitions */}
          <div>
            <h3 className={styles.subtitle}>DEFINITIONS</h3>
            <p>
              &quot;Force Majeure Event&quot; shall mean any event that is beyond our reasonable control and shall include, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action or any kind, riots, insurrection, war, acts of government, computer hacking, unauthorized access to your mobile or storage device, crashes, breach of security and encryption.
            </p>
            <br />
            <p>
              &quot;Personal Information&quot;  refers to any information that identifies or can be used to identify, contact or locate the person, to whom such information pertains including, but not limited to, name, address, phone number, email address, identification number or documents and any other information disclosed by you in relation to the Services.
            </p>
            <br />
            <p>
              &quot;Sensitive Information&quot; means sensitive personal data or information of a person means such personal information which consists of information relating to passwords, financial information such as bank account or credit card or debit card or other payment instrument details, biometric information, details of nominees and national identifiers including but not limited to: Aadhaar card, passport number, PAN, etc. For customers enrolled in Services provided by Klub personal information about the transaction is collected. Provided Any information that is freely available or accessible in public domain or furnished under the Right to Information Act, 2005 or any other law for the time being in force shall not be regarded as sensitive personal data or Sensitive Information for the purpose of these T&C.
            </p>
            <br />
            <p>&quot;Third Party&quot; refers to any person or entity other than you or us.</p>
            <br />
            <p>Personal Information / Sensitive Information that may be collected and manner of its use</p>
            <br />
            <p>Personal Information provided by you in relation to the use of the Website: In order for you to access the Website, we may collect your Personal Information and / or Sensitive Information as may be required for the purposes connected thereto. We endeavour to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you input.</p>
          </div>
          <br />

          {/* Cookies */}
          <div>
            <h3 className={styles.subtitle}>COOKIES</h3>
            <p>Cookies are small pieces of information saved by your browser onto your mobile device. Cookies are used to record various aspects of your visit and assist us to provide you with uninterrupted service.  Cookies may be set in your browser by us when you access the Services or may be set in when you visit Third Party websites.</p>
            <p>This anonymous information is maintained distinctly and is not linked to the Personal Information that you submit to us.  We use cookies collected to:</p>
            <br />
            <p>Authenticate your login information</p>
            <br />
            <p>Enable our security features;</p>
            <br />
            <p>Show you advertising; and</p>
            <br />
            <p>Improve and develop the features of the Website.</p>
            <br />
            <p>Please feel free to change your browser settings if you do not wish to accept cookies.  However, please note that changing your browser setting may affect your experience on the Services.</p>
          </div>
          <br />

          {/* Others */}
          <div>
            <h3 className={styles.subtitle}>OTHERS</h3>
            <p>
              In addition to the cookies, we may also collect information to enable us to better understand you so that we can improve your user experience; to assist our customer service and technical support personnel; and put processes in place to prevent fraud and unlawful use.  In an effort to make the Website affective and improve the Website, certain information may be collected each time you access the Website.  Such information may be saved in server logs in an encrypted form which may not identify you personally.  Such information or data may include, but shall not limit to, IP address, your server details, duration of your visit, date, time or purpose of your visit. In addition to cookies we use web beacons, ad tags, pixels to advertise as part of the Services. The abovementioned data may be used by us and may be shared with our sponsors, investors, advertisers, developers, strategic business partners or other associates to enhance and grow the user experience in relation to the Website.
            </p>
          </div>
          <br />

          {/* Purpose and Usage */}
          <div>
            <h3 className={styles.subtitle}>PURPOSE AND USAGE</h3>
            <p>Klub uses the information collected and appropriately notifies you to manage its business and offer an enhanced, personalised online experience on its website. Further, it enables Klub to:</p>
            <br />
            <p>Process applications, requests and transactions</p>
            <br />
            <p>Maintain internal records as per regulatory guidelines</p>
            <br />
            <p>Provide services to customers, including responding to customer requests</p>
            <br />
            <p>Comply with all applicable laws and regulations</p>
            <br />
            <p>Recognise the customer when he conducts online transactions</p>
            <br />
            <p>Understand the needs and provide relevant product and service offers</p>
            <br />
            <p>Obtain necessary rankings / ratings from various agencies (including CIBIL)</p>
          </div>
          <br />

          {/* Other uses of the information provided */}
          <div>
            <h3 className={styles.subtitle}>OTHER USES OF THE INFORMATION PROVIDED</h3>
            <p>We make all efforts to ensure that we collect only such Personal Information that we believe to be relevant in order to record, support, facilitate your access to the Website.</p>
          </div>
          <br />

          {/* Sharing information with Third Party service providers in relation to the Services */}
          <div>
            <h3 className={styles.subtitle}>SHARING INFORMATION WITH THIRD PARTY SERVICE PROVIDERS IN RELATION TO THE SERVICES</h3>
            <p>
              To the extent necessary to provide you the Services, we may provide your Personal Information to Third Party contractors who work on behalf of or with us to provide you with such services, to help us communicate with you or to maintain the Services.
            </p>
          </div>
          <br />

          {/* Communication */}
          <div>
            <h3 className={styles.subtitle}>COMMUNICATION</h3>
            <p>
              We may offer email, short message service (sms), multimedia message service (mms) or other forms of communication to share information with you about certain promotions or features the Services may choose to offer or about our affiliates, subsidiaries, business partners, advertisers and sponsors. You may receive such communication when you have registered as a User.
            </p>
          </div>
          <br />

          {/* Disclosure of Information */}
          <div>
            <h3 className={styles.subtitle}>DISCLOSURE OF INFORMATION</h3>
            <p>
              Klub may make disclosures of information diligently which is required pursuant to law or court order or under any legal procedure. We may or may not notify you for the same.
            </p>
          </div>
          <br />

          {/* Link to Third Party apps and websites */}
          <div>
            <h3 className={styles.subtitle}>LINK TO THIRD PARTY APPS AND WEBSITES</h3>
            <p>
              The Website and any communication sent to you may also contain/display Third Party advertisements and links to other websites or products and services. You agree and acknowledge that these apps and websites are operated by Third Parties and are not controlled by, or affiliated to, or associated with us unless expressly specified. We are not responsible for any form of transmission, whatsoever, received by you from any such Third Party. Accordingly, we do not make any representations concerning the privacy practices or policies of such Third Parties or terms of use of such websites or apps, nor do we control or guarantee the accuracy, integrity, or quality of the information, data, text, software, music, sound, photographs, graphics, videos, messages or other materials available on such websites or apps. The inclusion or exclusion does not imply any endorsement by us of the website, the Third Party, or the information on the website. The information provided by you to such Third Party websites shall be governed in accordance with the privacy policies of such websites and it is recommended that you review the privacy policy on any such websites and apps prior to using such websites.
            </p>
            <br />
            <p>
              These Third Party websites and apps and advertisers may use technology to send the advertisements directly to your browser. In such cases, the Third Party websites or advertisers automatically receive your IP address. They may also use cookies, JavaScript, web beacons and other technologies to measure the effectiveness of their advertisements and to personalize advertising content. We do not have access to or control over cookies or other features that such Third Party websites and apps and advertisers may use, and the information practices of these Third Party websites and apps and advertisers are not covered by this Privacy Policy. Please contact them directly for more information about their privacy practices.
            </p>
          </div>
          <br />

          {/* Security */}
          <div>
            <h3 className={styles.subtitle}>SECURITY</h3>
            <p>
              We strive to ensure the security, integrity and privacy of your Personal Information and Sensitive Information. To protect your Personal Information / Sensitive Information against unauthorized access or unauthorized alteration, disclosure or destruction.
            </p>
            <br />
            <p>
              We are not responsible for any breach of security or for any actions of any Third Parties that receive your Personal Information / Sensitive Information. We are not/shall be not responsible for their privacy policies or practices as it is beyond our control.
            </p>
            <br />
            <p>
              Not withstanding anything contained in this Privacy Policy or elsewhere, we shall not be held responsible for any loss, damage or misuse of your Personal Information / Sensitive Information, if such loss, damage or misuse is attributable to a Force Majeure Event.
            </p>
          </div>
          <br />

          {/* Retention */}
          <div>
            <h3 className={styles.subtitle}>RETENTION</h3>
            <p>
              Information may be retained for a duration, required by regulatory clauses or as long as required to achieve the identified (and notified) purpose. If you have any questions about this Privacy Policy, please feel free to write to us at <a href="mailto:info@klubworks.com" className={styles.highlight}>info@klubworks.com</a>
            </p>
          </div>
          <br />

          {/* Third Party content */}
          <div>
            <h3 className={styles.subtitle}>THIRD PARTY CONTENT</h3>
            <p>
              We cannot and will not assure that other Users are or will be complying with the foregoing rules or any other provisions of this T&C, and, as between you and us, you hereby assume all risk of harm or injury resulting from any such lack of compliance.
            </p>
            <br />
            <p>
              You acknowledge that when you access a link when accessing the Website, the site you will enter into is not controlled by us and different terms of use and privacy policy may apply. By assessing links, you acknowledge that we are not responsible for those sites. We reserve the right to disable links to and / or from third-party sites to the Website, although we are under no obligation to do so. If you have any queries, concerns or complaints about such third-party websites or mobile applications you must direct them to the operator of that third party website or mobile application.
            </p>
            <br />
            <p>
              We have no control over and accept no responsibility for the content of any website or mobile application to which a link from the Website exists (unless we are the provider of those linked websites or mobile applications). Such linked websites and mobile applications are provided “as is” for your convenience only with no warranty, express or implied, for the information provided within them.
            </p>
          </div>
          <br />
        </div>
      </div>
    </div>
  </Layout>
);

export default PrivacyPolicy;
